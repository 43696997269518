* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Nunito Sans', sans-serif;
  color: var(--color--main-dark);
}

body{
  background: var(--color--main-gray);
}

html,
body,
#root{
  width: 100%;
  height: 100%;
}

img {
  object-fit: contain;
}

button {
  cursor: pointer;
  border: none;
}
