.admin-panel__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &-container {
    padding: 28px 48px;

    background: var(--color--white);
  }

  .admin-panel__header__nav {
    display: inline-grid;
    grid-template-columns: repeat(2, auto);
    gap: 85px;
  }

  .admin-panel__links {
    display: grid;
    grid-template-columns: repeat(3, auto);
    gap: 40px;

    .admin-panel__link {
      font-weight: 800;
      font-size: 16px;
      line-height: 24px;

      text-decoration: none;

      color: var(--color--grey);

      user-select: none;

      &.active {
        color: var(--color--light-black);
      }
    }
  }

  .search-icon {
    cursor: pointer;
    user-select: none;
  }
}
