.container {
  position: relative;

  .username {
    cursor: pointer;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
  }

  .dropdown {
    z-index: 1;
    position: absolute;
    background-color: gray;
    top: 100%;
    margin-top: 20px;
    right: 0px;

    border-radius: 10px;
    background-color: white;
    box-shadow: 0 2px 10px rgba($color: #000000, $alpha: 0.1);
    width: 150px;
    display: flex;
    flex-direction: column;

    .dropdownItem {
      padding: 8px 12px;
      border-bottom: 1px rgb(240, 237, 237) solid;
      display: inline-flex;
      justify-content: center;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .userInfo {
      width: 100%;
      justify-content: flex-start;
      font-size: 14px;

      .usernameLabel {
        margin-right: 5px;
        user-select: none;
        color: gray;
      }
    }

    .logout {
      background-color: rgba($color: #d0006e, $alpha: 0.8);
      cursor: pointer;
      padding: 8px 20px;
      font-size: 14px;
      border-radius: 20px;
      color: white;
      user-select: none;
      transition: background-color 0.3s;
      text-align: center;
      width: 100px;
      font-weight: 700;

      &:hover {
        background-color: rgba($color: #d0006e, $alpha: 1);
      }
    }
  }
}
