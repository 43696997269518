.alert {
  z-index: 1056;
  position: fixed;
  top: 20px;
  left: 50%;

  transform: translateX(-50%);

  padding: 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;

  animation: alert-display 3s;
}

.success {
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc;
}

.error {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
}
