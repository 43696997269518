:root {
  --color--white: #ffffff;
  --color--pink: #d0006e;
  --color--main-dark: #0b080f;
  --color--black: #2f2f2f;
  --color--grey-dark: #5d5e60;
  --color--blue: #0052dd;
  --color--red: #ea4335;
  --color--light-black: #11263c;
  --color--grey: #d0d1d2;
  --color--light-gray: #d2d4e1;
  --color--main-gray: #F9F9F9;

}
